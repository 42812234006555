<template>
<section class="statistics">
  <right-background />

  <div class="main-container statistics__container" id="statistic">
    <h1 class="title">
      Статистика
    </h1>

    <div class="statistics__numbers">
      <ul class="statistics__list" >
        <li class="statistics__item" v-for="stat, index in statistic" :key="index"
        :ref="'statItem' + index" data-animated="false" :data-number="stat.number"
        data-animatedNumber="0"  v-scrollanimation>
          <div class="statistics__img">
            <img class="statistics__img-pic" alt="statistics" :src="stat.urlPic">
          </div>

          <div class="statistics__box">
            <div class="statistics__number">
              {{stat.arrow}}
                <p class="statistics__number-num">
                  {{stat.animatedNumber}}
                </p>

              <span>{{stat.numbertext}}</span>
            </div>
            <p class="statistics__text">{{ stat.text }}</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</section>
</template>

<script>
import RightBackground from '@/components/shared/background/RightBg.vue';

export default {
  components: {
    RightBackground,
  },
  data() {
    return {
      animatedScrollObserver: null,
      statistic: [{
        urlPic: '/svg/stat/circuit.svg',
        number: '7',
        numbertext: '',
        text: 'стран, для которых ведутся исследования',
        arrow: '',
      },
      {
        urlPic: '/svg/stat/leaf.svg',
        number: '25 000',
        numbertext: '',
        text: 'ПОЛЕВЫХ ЭКСПЕРИМЕНТОВ',
        arrow: '›',
      },
      {
        urlPic: '/svg/stat/earth.svg',
        number: '50',
        numbertext: 'млн га',
        text: 'общая площадь мониторинга и оценки урожайности',
        arrow: '›',
      },
      {
        urlPic: '/svg/stat/brain.svg',
        number: '8 000',
        numbertext: '',
        text: 'обученных моделей урожайности (продуктивности)',
        arrow: '›',
      },
      {
        urlPic: '/svg/stat/case.svg',
        number: '25',
        numbertext: '',
        text: 'КЕЙСОВ',
        arrow: '›',
      },
      {
        urlPic: '/svg/stat/sputnik.svg',
        number: '600',
        numbertext: 'ТБ',
        text: 'объем данных ДЗЗ, накопленных с начала текущего года',
        arrow: '›',
      },
      ],
      lastScrollY: window.scrollY, // Инициализация переменной положения скролла
      isScrollingDown: true,
    };
  },
  mounted() {
    this.createObserver();
    window.addEventListener('scroll', this.handleScroll); // Добавляем обработчик события
    this.$nextTick(() => {
      this.statistic.forEach((item, index) => {
        const elements = this.$refs[`statItem${index}`]; // Получаем массив элементов
        if (elements && elements.length > 0) {
          const element = elements[0]; // Получаем первый элемент массива
          if (element) {
            this.animatedScrollObserver.observe(element);
          } else {
            console.error(`Элемент с рефом 'statItem${index}' не найден.`);
          }
        } else {
          console.error(`Массив с рефом 'statItem${index}' пуст или не найден.`);
        }
      });
    });
  },

  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll); // Удаляем обработчик события
  },

  methods: {
    handleScroll() {
      const { scrollY } = window;
      window.requestAnimationFrame(() => {
        if (scrollY > this.lastScrollY) {
          this.isScrollingDown = true; // Прокрутка вниз
        } else {
          this.isScrollingDown = false; // Прокрутка вверх
        }
        this.lastScrollY = scrollY; // Обновляем значение lastScrollY
      });
    },

    createObserver() {
      const items = this.$el.querySelectorAll('.statistics__item');

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          const item = entry.target;
          const animatedFlag = item.dataset.animated;

          if (entry.isIntersecting) {
            // Элемент стал видимым
            if (animatedFlag !== 'true') {
              item.classList.add('enter'); // Запускаем анимацию появления
              this.animateNumber(item);
              item.dataset.animated = 'true'; // Устанавливаем флаг
            }
          } else if (!this.isScrollingDown) {
            // Элемент уходит из видимости и мы прокручиваем вверх
            item.classList.remove('enter'); // Убираем анимацию появления
            item.dataset.animated = 'false'; // Сбрасываем флаг
          }
        });
      }, { threshold: 0.1 });

      // Отслеживайте прокрутку, чтобы определить направление
      this.handleScroll();

      items.forEach((item) => observer.observe(item)); // Начинаем наблюдение за элементами
    },

    animateNumber(item) {
      // Получаем значение из data-number, убираем пробел в числе, если он есть
      const totalString = item.getAttribute('data-number');
      const total = parseInt(totalString.replace(/\s+/g, ''), 10);
      let animatedNumber = parseInt(item.getAttribute('data-animatedNumber'), 10);

      const intervalTime = 200;
      let increment;

      // Определяем величину приростов
      if (total >= 0 && total < 15) {
        increment = 1;
      } else if (total >= 15 && total < 30) {
        increment = 2;
      } else if (total >= 30 && total < 100) {
        increment = 5;
      } else if (total >= 100 && total < 1000) {
        increment = 50;
      } else {
        increment = Math.ceil(total / 10); // Пример для более высокой разрядности
      }

      const numberElement = item.querySelector('.statistics__number-num'); // Получаем элемент с номером

      const interval = setInterval(() => {
        if (animatedNumber < total) {
          // Увеличиваем значение до максимума
          animatedNumber = Math.min(total, animatedNumber + increment);
          if (numberElement) {
            numberElement.innerText = animatedNumber; // Обновляем текст внутри элемента
          }
        } else {
          clearInterval(interval); // Останавливаем интервал, когда достигли значения
        }
      }, intervalTime);
    },
  },
};
</script>

<style lang="scss" scoped>
.statistics {
  min-height: 650px;
  color: #fff;
  overflow: hidden;

  &__container {
    position: relative;

    @media (max-width: 510px) {
      padding: 0 20px;
    }
  }

  &-background {
    position: absolute;

    &--top {
      top: 0;
      right: 0;
    }

    &--bottom {
      bottom: -90px;
      left: 0;

    }
  }

  &__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__item {
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;

    &:first-child {
      .statistics__box {
        flex-direction: row;

        @media (max-width: 720px) {
          flex-direction: column;
        }
      }

      .statistics__text {
        text-align: initial;
        padding: 0 0 0 20px;
        width: 203px;

        @media (max-width: 1045px) {
          width: 180px;
        }

        @media (max-width: 925px) {
          width: 160px;
        }

        @media (max-width: 720px) {
          text-align: center;
          padding: 0;
          width: 145px;
        }

        @media (max-width: 440px) {
          width: 120px;
        }
      }
    }

    &:nth-child(2) .statistics__text {
      max-width: 286px;

      @media (max-width: 925px) {
        max-width: 212px;
      }

      @media (max-width: 440px) {
        max-width: 172px;
      }
    }

    @media (max-width: 680px) {
      width: 50%;
    }
  }

  // numbers
  &__numbers {
    width: 90%;
    margin: 0 auto;

    @media (max-width: 1185px) {
      width: 100%;
    }
  }

  &__number {
    display: flex;
    justify-content: center;
    font-family: 'montserratbold', sans-serif;
    font-size: 96px;
    line-height: 56px;
    margin: 25px 0px 15px;
    align-items: baseline;

    & span {
      font-family: 'montserratbold', sans-serif;
      font-size: 38px;
      line-height: 32px;

      @media (max-width: 1390px) {
        font-size: 24px;
      }

      @media (max-width: 480px) {
        font-size: 18px;
      }
    }

    @media (max-width: 1390px) {
      font-size: 78px;
    }

    @media (max-width: 1045px) {
      font-size: 64px;
    }

    @media (max-width: 925px) {
      font-size: 50px;
      line-height: 35px;
    }

    @media (max-width: 925px) {
      font-size: 40px;
      line-height: 30px;
    }

    @media (max-width: 680px) {
      margin: 20px 0px 5px;
    }

    @media (max-width: 375px) {
      font-size: 36px;
      line-height: 28px;
    }
  }

  &__text {
    font-family: 'montserratbold', sans-serif;
    font-size: 18px;
    line-height: 22px;
    max-width: 234px;
    text-align: center;

    @media (max-width: 1045px) {
      font-size: 16px;
    }

    @media (max-width: 925px) {
      font-size: 14px;
      line-height: 18px;
      max-width: 212px;
    }

    @media (max-width: 440px) {
      font-size: 12px;
      line-height: 16px;
      max-width: 172px;
    }
  }

  &__box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 925px) {
    &__img {
      text-align: center;
    }

    &__img-pic {
      width: 70%;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
      border-radius: 50%;

      @media (max-width: 510px) {
        width: 66%;
      }
    }
  }
}

.before-enter {
  opacity: 0;
  transform: translateY(140px);
  -webkit-transform: translateY(140px);
  transition: opacity 1.2s, transform 1.2s;
  transition-delay: .3s;
  transition-timing-function: ease;
}

.enter {
  opacity: 1;
  transform: translateY(0px);
  -webkit-transform: translateY(0px);
}
</style>
